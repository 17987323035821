// @mui
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import Avatar from '@mui/material/Avatar';
import Divider from '@mui/material/Divider';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import ListItemText from '@mui/material/ListItemText';

import { useSnackbar } from 'src/components/snackbar';
import React, { StrictMode, useCallback, useState } from 'react';
import useLocales from 'src/locales/use-locales';
import axios, { API_ENDPOINTS } from 'src/utils/axios';
// routes
import { paths } from 'src/routes/paths';
// components
import Iconify from 'src/components/iconify';
import { RouterLink } from 'src/routes/components';
import CustomPopover, { usePopover } from 'src/components/custom-popover';
import { IOrderItem } from 'src/types/order';
import Label from 'src/components/label';
import { format } from 'date-fns';
import { LoadingButton } from '@mui/lab';
import { Button, FormControl, InputLabel, OutlinedInput, Select } from '@mui/material';
import { useBoolean } from 'src/hooks/use-boolean';
import { useRouter } from 'src/routes/hook/use-router';
import { Mixpanel } from 'src/Mixpanel';
import OrderRejectedModal from './order-rejected-modal';
import OrderPickUpTimeModal from './order-pickupTime-modal';

// ----------------------------------------------------------------------

type Props = {
  order: any;
  onView: VoidFunction;
  onEdit: VoidFunction;
  onDelete: VoidFunction;
  onClose: VoidFunction;
};

export default function OrderItemModal({ order, onView, onEdit, onDelete, onClose }: Props) {
  const popover = usePopover();
  const { t } = useLocales();
  const router = useRouter();
  const [pickUpTime, setpickUpTime] = useState('0');
  const getColor = (
    nextState: any
  ): 'success' | 'inherit' | 'primary' | 'secondary' | 'error' | 'info' | 'warning' =>
    nextState?.class === 'order-state-accepted'
      ? 'success'
      : nextState?.class === 'order-state-rejected'
        ? 'error'
        : 'inherit';

  //  const { id, name, address, state, delivery, takeaway, store_categories} = order;
  const [currentOrder, setCurrentOrder] = useState(order);
  const {
    state,
    id,
    customer,
    paymentMethod,
    created_at,
    type,
    conversation,
    store,
    orderProducts,
    nextStates,
  } = currentOrder;
  const rejectModal = useBoolean();
  const closeModal = () => {
    rejectModal.onFalse();
  };
  const { enqueueSnackbar } = useSnackbar();

  const rejectOrder = useCallback(
    async (denyReason_id: string) => {
      axios
        .put(API_ENDPOINTS.order.deny(id, denyReason_id))
        .then(({ data }) => {
          Mixpanel.track('Rejected Order', { id, denyReasonId: denyReason_id });
          enqueueSnackbar('Orden rechazada');
          setCurrentOrder(data?.order);
          onClose();
          router.reload();
        })
        .catch((error) => {

        });
    },
    [id, enqueueSnackbar, onClose, router]
  );

  const changeStatus = useCallback(
    async (URL: string) => {
      onClose();
      axios
        .put(URL)
        .then(({ data }) => {

          enqueueSnackbar('Se actualizó el estado de la orden');
          setCurrentOrder(data?.order);
          router.reload();
        })
        .catch((error) => {

        });
    },
    [enqueueSnackbar, onClose, router]
  );
  const updateState = (stateClass: string) => {

    let URL = '';
    let TIME;
    switch (stateClass) {
      case 'order-state-accepted':
        TIME = (pickUpTime === '0') ? '' : String(pickUpTime);
        URL = API_ENDPOINTS.order.accepted(id, TIME);
        Mixpanel.track('Accepted Order', { id, pickUpTime: TIME });
        break;
      case 'order-state-rejected':
        rejectModal.onTrue();
        break;
      case 'order-state-finalized':
        URL = API_ENDPOINTS.order.finalized(id);
        Mixpanel.track('Completed Order', { id });
        break;
      case 'order-state-done':
        URL = API_ENDPOINTS.order.done(id);
        break;
      case 'order-state-ready-to-pick-up':
        URL = API_ENDPOINTS.order.readyToPickup(id);
        break;
      case 'order-state-sent':
        URL = API_ENDPOINTS.order.sent(id);
        break;
      default:
        break;
    }
    if (URL) {
      changeStatus(URL);
    }
  };
  return (
    <>
      <Card onClick={(event) => event.stopPropagation()}>
        {/* <IconButton onClick={popover.onOpen} sx={{ position: 'absolute', top: 8, right: 8 }}>
          <Iconify icon="eva:more-vertical-fill" />
        </IconButton> */}

        <Stack sx={{ p: 3, pb: 2 }}>
          <ListItemText
            sx={{ mb: 0.5 }}
            primary={
              <Link component={RouterLink} href={paths.dashboard.order.details(id)} color="inherit">
                <Stack direction="row" spacing={3} useFlexGap flexWrap="wrap" alignItems="center">
                  #{id}
                  <Label
                    variant="soft"
                    color={
                      (state?.class === 'order-state-accepted' && 'success') ||
                      (state?.class === 'order-state-created' && 'warning') ||
                      (state?.class === 'order-state-rejected' && 'error') ||
                      (state?.class === 'order-state-finalized' && 'default') ||
                      'primary'
                    }
                  >
                    {t(state?.name)}
                  </Label>
                  <Typography variant="caption" color="grey">
                    {format(new Date(created_at), 'dd MMM yyyy, p')}
                  </Typography>
                </Stack>
              </Link>
            }
            primaryTypographyProps={{
              typography: 'subtitle1',
            }}
          />

          <Divider sx={{ borderStyle: 'groove' }} />
          <Stack spacing={0.5} sx={{ mt: 0.5, mb: 1 }}>
            <Stack spacing={1} direction="row" alignItems="center" sx={{ typography: 'caption' }}>
              <Iconify width={16} icon="clarity:store-line" color="primary.main" />
              {store?.name}
            </Stack>
            <Stack spacing={1} direction="row" alignItems="center" sx={{ typography: 'caption' }}>
              <Iconify width={16} icon="tabler:user" color="primary.main" />
              {customer?.name}, {customer?.username}
            </Stack>
            <Stack spacing={1} direction="row" alignItems="center" sx={{ typography: 'caption' }}>
              <Iconify width={16} icon="tdesign:money" color="primary.main" />
              {t(`paymentMethod.${paymentMethod?.name}`)} {paymentMethod?.enterprise}
            </Stack>
            <Stack spacing={1} direction="row" alignItems="center" sx={{ typography: 'caption' }}>
              <Iconify
                width={16}
                icon={type?.id === 1 ? 'ic:baseline-delivery-dining' : 'ep:takeaway-box'}
                color="primary.main"
              />
              {type?.name}{' '}
              <Typography variant="caption" noWrap color="black">
                {!!type?.address && `${type?.address?.street} ${type?.address?.houseNumber} ${type?.address?.details}`}
              </Typography>
            </Stack>
          </Stack>
          <Divider sx={{ borderStyle: 'dashed' }} />
          {currentOrder?.new_method_used && (
            <Stack direction="column" sx={{ mt: 2, whiteSpace: 'break-spaces' }}>
              <Typography variant="body2" color="black">
                {currentOrder?.conversation?.conversation_summary}
              </Typography>
            </Stack>
          )}
          <Stack direction="column" sx={{ mt: 2 }}>
            {orderProducts.map((orderProduct: any) => (

              <React.Fragment key={`product-modal-${orderProduct.id}`}>
                <Typography variant="subtitle1" color="black" >
                  - <strong>{orderProduct?.quantity} x</strong> {orderProduct?.product?.name}
                  {orderProduct?.comment ? `- ${orderProduct?.comment}` : ''}
                </Typography>
                {orderProduct.orderProductAddons.map((addon: any) =>
                  <Typography variant="subtitle2" color="black" key={`product-addon-${addon.id}`} sx={{ px: 3 }}>
                    - <strong>{addon?.quantity} x</strong> {addon?.productAddon?.name}
                  </Typography>
                )}
              </React.Fragment>
            ))}
          </Stack>
        </Stack>
        <Stack spacing={1.5} sx={{ p: 2 }}>
          <FormControl
            sx={{
              flexShrink: 0
            }}
          >
            <InputLabel>Tiempo de preparación</InputLabel>
            <Select
              value={pickUpTime}
              onChange={(ev: any) => {
                ev.stopPropagation();
                setpickUpTime(ev.target.value)
              }}
              input={<OutlinedInput label="Tiempo de preparación" />}
              // renderValue={(selected) => categoryOptions.find((category) => category.id === selected).name}
              sx={{ textTransform: 'capitalize' }}
              defaultValue="0"
            >
              <MenuItem key='pickup-empty' value='0' >
                No indicar
              </MenuItem>
              <MenuItem key='pickup-15' value='15'>
                15 {t('minutes')}
              </MenuItem>
              <MenuItem key='pickup-30' value='30'>
                30 {t('minutes')}
              </MenuItem>
              <MenuItem key='pickup-45' value='45'>
                45 {t('minutes')}
              </MenuItem>
              <MenuItem key='pickup-60' value='60'>
                60 {t('minutes')}
              </MenuItem>
            </Select>
          </FormControl>
        </Stack>
        <Stack spacing={1.5} display="grid" gridTemplateColumns="repeat(2, 1fr)" sx={{ p: 3 }}>
          {nextStates.map((nextState: any) => (
            <Button
              fullWidth
              key={`order${id}-${nextState.class}`}
              color={getColor(nextState)}
              variant="contained"
              onClick={() => updateState(nextState?.class)}
            >
              {t(`state.${nextState?.name}`)}
            </Button>
          ))}
        </Stack>
        <Button
          fullWidth
          key={`detail-order-${order.id}`}
          variant="contained"
          onClick={() => {
            router.push(paths.dashboard.order.details(order.id));
            onView();
          }}
        >
          Ver detalle
        </Button>
      </Card>

      <OrderRejectedModal
        open={rejectModal.value}
        storeId={store.id}
        onClose={closeModal}
        onReject={rejectOrder}
      />

      <CustomPopover
        open={popover.open}
        onClose={popover.onClose}
        arrow="right-top"
        sx={{ width: 140 }}
      >
        <MenuItem
          onClick={() => {
            popover.onClose();
            onView();
          }}
        >
          <Iconify icon="solar:eye-bold" />
          Detalle
        </MenuItem>

        <MenuItem
          onClick={() => {
            popover.onClose();
            onEdit();
          }}
        >
          <Iconify icon="solar:pen-bold" />
          Editar
        </MenuItem>

        <MenuItem
          onClick={() => {
            popover.onClose();
            onDelete();
          }}
          sx={{ color: 'error.main' }}
        >
          <Iconify icon="solar:trash-bin-trash-bold" />
          Eliminar
        </MenuItem>
      </CustomPopover>
    </>
  );
}
