import { Suspense, lazy } from 'react';
import { Outlet } from 'react-router-dom';
// auth
import { AuthGuard } from 'src/auth/guard';
// layouts
import DashboardLayout from 'src/layouts/dashboard';
// components
import { LoadingScreen } from 'src/components/loading-screen';
import CategoryListPage from 'src/pages/dashboard/category/list';
import CategoryCreatePage from 'src/pages/dashboard/category/new';
import CategoryEditPage from 'src/pages/dashboard/category/edit';
import DenyReasonListPage from 'src/pages/dashboard/denyReason/list';
import DenyReasonCreatePage from 'src/pages/dashboard/denyReason/new';
import DenyReasonEditPage from 'src/pages/dashboard/denyReason/edit';
import ChannelListPage from 'src/pages/dashboard/channel/list';
import InstanceListPage from 'src/pages/dashboard/instance/list';
import StoreCategoryListPage from 'src/pages/dashboard/storeCategory/list';
import StoreCategoryCreatePage from 'src/pages/dashboard/storeCategory/new';
import StoreCategoryEditPage from 'src/pages/dashboard/storeCategory/edit';
import ProductAddonsListPage from 'src/pages/dashboard/productAddons/list';
import ProductAddonsCreatePage from 'src/pages/dashboard/productAddons/new';
import ProductAddonsEditPage from 'src/pages/dashboard/productAddons/edit';
import ProductAddonsDetailsPage from 'src/pages/dashboard/productAddons/details';
import StoreSettingsPage from 'src/pages/dashboard/store/settings';
import ProductCombosListPage from 'src/pages/dashboard/productCombos/list';
import ProductCombosCreatePage from 'src/pages/dashboard/productCombos/new';
import ProductsComboEditPage from 'src/pages/dashboard/productCombos/edit';
import StoreFlowMessagesPage from 'src/pages/dashboard/store/flow-messages';




// ----------------------------------------------------------------------

// OVERVIEW
const IndexPage = lazy(() => import('src/pages/dashboard/app'));
const OverviewEcommercePage = lazy(() => import('src/pages/dashboard/ecommerce'));
const OverviewAnalyticsPage = lazy(() => import('src/pages/dashboard/analytics'));
const OverviewBankingPage = lazy(() => import('src/pages/dashboard/banking'));
const OverviewBookingPage = lazy(() => import('src/pages/dashboard/booking'));
const OverviewFilePage = lazy(() => import('src/pages/dashboard/file'));
// PRODUCT
const ProductDetailsPage = lazy(() => import('src/pages/dashboard/product/details'));
const ProductListPage = lazy(() => import('src/pages/dashboard/product/list'));
const ProductCreatePage = lazy(() => import('src/pages/dashboard/product/new'));
const ProductEditPage = lazy(() => import('src/pages/dashboard/product/edit'));
// ORDER
const OrderListPage = lazy(() => import('src/pages/dashboard/order/list'));
const OrderDetailsPage = lazy(() => import('src/pages/dashboard/order/details'));
// INVOICE
const InvoiceListPage = lazy(() => import('src/pages/dashboard/invoice/list'));
const InvoiceDetailsPage = lazy(() => import('src/pages/dashboard/invoice/details'));
const InvoiceCreatePage = lazy(() => import('src/pages/dashboard/invoice/new'));
const InvoiceEditPage = lazy(() => import('src/pages/dashboard/invoice/edit'));
// USER
const UserProfilePage = lazy(() => import('src/pages/dashboard/user/profile'));
const UserCardsPage = lazy(() => import('src/pages/dashboard/user/cards'));
const UserListPage = lazy(() => import('src/pages/dashboard/user/list'));
const UserAccountPage = lazy(() => import('src/pages/dashboard/user/account'));
const UserCreatePage = lazy(() => import('src/pages/dashboard/user/new'));
const UserEditPage = lazy(() => import('src/pages/dashboard/user/edit'));
// BLOG
const BlogPostsPage = lazy(() => import('src/pages/dashboard/post/list'));
const BlogPostPage = lazy(() => import('src/pages/dashboard/post/details'));
const BlogNewPostPage = lazy(() => import('src/pages/dashboard/post/new'));
const BlogEditPostPage = lazy(() => import('src/pages/dashboard/post/edit'));
// JOB
const JobDetailsPage = lazy(() => import('src/pages/dashboard/job/details'));
const JobListPage = lazy(() => import('src/pages/dashboard/job/list'));
const JobCreatePage = lazy(() => import('src/pages/dashboard/job/new'));
const JobEditPage = lazy(() => import('src/pages/dashboard/job/edit'));
// TOUR
const TourDetailsPage = lazy(() => import('src/pages/dashboard/tour/details'));
const TourListPage = lazy(() => import('src/pages/dashboard/tour/list'));
const TourCreatePage = lazy(() => import('src/pages/dashboard/tour/new'));
const TourEditPage = lazy(() => import('src/pages/dashboard/tour/edit'));
// FILE MANAGER
const FileManagerPage = lazy(() => import('src/pages/dashboard/file-manager'));
// APP
const ChatPage = lazy(() => import('src/pages/dashboard/chat'));
const MailPage = lazy(() => import('src/pages/dashboard/mail'));
const CalendarPage = lazy(() => import('src/pages/dashboard/calendar'));
const KanbanPage = lazy(() => import('src/pages/dashboard/kanban'));
// TEST RENDER PAGE BY ROLE
const PermissionDeniedPage = lazy(() => import('src/pages/dashboard/permission'));
// BLANK PAGE
const BlankPage = lazy(() => import('src/pages/dashboard/blank'));
// PROMPT
const PromptListPage = lazy(() => import('src/pages/dashboard/prompt/list'));
const PromptCreatePage = lazy(() => import('src/pages/dashboard/prompt/new'));
const PromptEditPage = lazy(() => import('src/pages/dashboard/prompt/edit'));
// CURRENCY
const CurrencyListPage = lazy(() => import('src/pages/dashboard/currency/list'));
const CurrencyCreatePage = lazy(() => import('src/pages/dashboard/currency/new'));
const CurrencyEditPage = lazy(() => import('src/pages/dashboard/currency/edit'));
// COUNTRY
const CountryListPage = lazy(() => import('src/pages/dashboard/country/list'));
const CountryCreatePage = lazy(() => import('src/pages/dashboard/country/new'));
const CountryEditPage = lazy(() => import('src/pages/dashboard/country/edit'));
// PROVINCE
const ProvinceListPage = lazy(() => import('src/pages/dashboard/province/list'));
const ProvinceCreatePage = lazy(() => import('src/pages/dashboard/province/new'));
const ProvinceEditPage = lazy(() => import('src/pages/dashboard/province/edit'));
// CITY
const CityListPage = lazy(() => import('src/pages/dashboard/city/list'));
const CityCreatePage = lazy(() => import('src/pages/dashboard/city/new'));
const CityEditPage = lazy(() => import('src/pages/dashboard/city/edit'));
// STORE
const StoreListPage = lazy(() => import('src/pages/dashboard/store/list'));
const StoreCreatePage = lazy(() => import('src/pages/dashboard/store/new'));
const StoreEditPage = lazy(() => import('src/pages/dashboard/store/edit'));
const StoreDetailsPage = lazy(() => import('src/pages/dashboard/store/details'));

// PLAYGROUND 
const PlaygroundPage = lazy(() => import('src/pages/dashboard/playground'));

// PAYMENT-METHODS 
const PaymentMethodsPage = lazy(() => import('src/pages/dashboard/payment-methods'));
const PaymentMethodsEditPage = lazy(() => import('src/pages/dashboard/payment-methods-edit'));

// CONVERSATION
const ConversationPage = lazy(() => import('src/pages/dashboard/conversation'));

// BANNED-USER 
const BannedUserListPage = lazy(() => import('src/pages/dashboard/bannedUser/list'));
const BannedUserCreatePage = lazy(() => import('src/pages/dashboard/bannedUser/new'));

// WHASTAPP-TEST
const WhatsappTestListPage =  lazy(() => import('src/pages/dashboard/whatsappTest/list'));

// PAUSED-USER
const PausedUserListPage = lazy(() => import('src/pages/dashboard/pausedUser/list'));
const PausedUserCreatePage = lazy(() => import('src/pages/dashboard/pausedUser/new'));

// ----------------------------------------------------------------------

export const dashboardRoutes = [
  {
    path: 'dashboard',
    element: (
      <AuthGuard>
        <DashboardLayout>
          <Suspense fallback={<LoadingScreen />}>
            <Outlet />
          </Suspense>
        </DashboardLayout>
      </AuthGuard>
    ),
    children: [
      { element: <IndexPage />, index: true },
      { path: 'ecommerce', element: <OverviewEcommercePage /> },
      { path: 'analytics', element: <OverviewAnalyticsPage /> },
      { path: 'banking', element: <OverviewBankingPage /> },
      { path: 'booking', element: <OverviewBookingPage /> },
      { path: 'file', element: <OverviewFilePage /> },
      {
        path: 'user',
        children: [
          { element: <UserProfilePage />, index: true },
          { path: 'profile', element: <UserProfilePage /> },
          { path: 'cards', element: <UserCardsPage /> },
          { path: 'list', element: <UserListPage /> },
          { path: 'new', element: <UserCreatePage /> },
          { path: ':id/edit', element: <UserEditPage /> },
          { path: 'account', element: <UserAccountPage /> },
        ],
      },
      {
        path: 'category',
        children: [
          { element: <CategoryListPage />, index: true },
          { path: 'list', element: <CategoryListPage /> },
          { path: 'new', element: <CategoryCreatePage /> },
          { path: ':id/edit', element: <CategoryEditPage /> },
        ],
      },
      {
        path: 'product',
        children: [
          { element: <ProductListPage />, index: true },
          { path: 'list', element: <ProductListPage /> },
          { path: ':id', element: <ProductDetailsPage /> },
          { path: 'new', element: <ProductCreatePage /> },
          { path: ':id/edit', element: <ProductEditPage /> },
        ],
      },
      {
        path: 'order',
        children: [
          { element: <OrderListPage />, index: true },
          { path: 'list', element: <OrderListPage /> },
          { path: ':id', element: <OrderDetailsPage /> },
        ],
      },
      {
        path: 'invoice',
        children: [
          { element: <InvoiceListPage />, index: true },
          { path: 'list', element: <InvoiceListPage /> },
          { path: ':id', element: <InvoiceDetailsPage /> },
          { path: ':id/edit', element: <InvoiceEditPage /> },
          { path: 'new', element: <InvoiceCreatePage /> },
        ],
      },
      {
        path: 'post',
        children: [
          { element: <BlogPostsPage />, index: true },
          { path: 'list', element: <BlogPostsPage /> },
          { path: ':title', element: <BlogPostPage /> },
          { path: ':title/edit', element: <BlogEditPostPage /> },
          { path: 'new', element: <BlogNewPostPage /> },
        ],
      },
      {
        path: 'job',
        children: [
          { element: <JobListPage />, index: true },
          { path: 'list', element: <JobListPage /> },
          { path: ':id', element: <JobDetailsPage /> },
          { path: 'new', element: <JobCreatePage /> },
          { path: ':id/edit', element: <JobEditPage /> },
        ],
      },
      {
        path: 'tour',
        children: [
          { element: <TourListPage />, index: true },
          { path: 'list', element: <TourListPage /> },
          { path: ':id', element: <TourDetailsPage /> },
          { path: 'new', element: <TourCreatePage /> },
          { path: ':id/edit', element: <TourEditPage /> },
        ],
      },
      { path: 'file-manager', element: <FileManagerPage /> },
      { path: 'mail', element: <MailPage /> },
      { path: 'chat', element: <ChatPage /> },
      { path: 'playground', element: <PlaygroundPage /> },
      {
        path: 'paymentmethod',
        children: [
          { element: <PaymentMethodsPage />, index: true },
          { path: 'list', element: <PaymentMethodsPage /> },
          { path: ':id/edit', element: <PaymentMethodsEditPage /> },
        ],
      },
      { path: 'conversation', element: <ConversationPage /> },
      { path: 'calendar', element: <CalendarPage /> },
      { path: 'kanban', element: <KanbanPage /> },
      { path: 'permission', element: <PermissionDeniedPage /> },
      { path: 'blank', element: <BlankPage /> },
      {
        path: 'prompt',
        children: [
          { element: <PromptListPage />, index: true },
          { path: 'list', element: <PromptListPage /> },
          { path: 'new', element: <PromptCreatePage /> },
          { path: ':id/edit', element: <PromptEditPage /> },
        ],
      },
      {
        path: 'currency',
        children: [
          { element: <CurrencyListPage />, index: true },
          { path: 'list', element: <CurrencyListPage /> },
          { path: 'new', element: <CurrencyCreatePage /> },
          { path: ':id/edit', element: <CurrencyEditPage /> },
        ],
      },
      {
        path: 'country',
        children: [
          { element: <CountryListPage />, index: true },
          { path: 'list', element: <CountryListPage /> },
          { path: 'new', element: <CountryCreatePage /> },
          { path: ':id/edit', element: <CountryEditPage /> },
        ],
      },
      {
        path: 'province',
        children: [
          { element: <ProvinceListPage />, index: true },
          { path: 'list', element: <ProvinceListPage /> },
          { path: 'new', element: <ProvinceCreatePage /> },
          { path: ':id/edit', element: <ProvinceEditPage /> },
        ],
      },
      {
        path: 'city',
        children: [
          { element: <CityListPage />, index: true },
          { path: 'list', element: <CityListPage /> },
          { path: 'new', element: <CityCreatePage /> },
          { path: ':id/edit', element: <CityEditPage /> },
        ],
      },
      {
        path: 'store',
        children: [
          { element: <StoreListPage />, index: true },
          { path: ':id/:tab', element: <UserAccountPage /> },
          { path: ':id', element: <UserAccountPage /> },
          { path: 'list', element: <StoreListPage /> },
          { path: 'new', element: <StoreCreatePage /> },
          { path: ':id/edit', element: <StoreEditPage /> },
          { path: ':id/settings', element: <StoreSettingsPage /> },
          { path: ':id/flow-messages', element: <StoreFlowMessagesPage /> },
        ],
      },
      {
        path: 'denyReason',
        children: [
          { element: <DenyReasonListPage />, index: true },
          { path: 'list', element: <DenyReasonListPage /> },
          { path: 'new', element: <DenyReasonCreatePage /> },
          { path: ':id/edit', element: <DenyReasonEditPage /> },
        ],
      },
      {
        path: 'channel',
        children: [
          { element: <ChannelListPage />, index: true },
          { path: 'list', element: <ChannelListPage /> }
        ],
      },
      {
        path: 'instance',
        children: [
          { element: <InstanceListPage />, index: true },
          { path: 'list', element: <InstanceListPage /> }
        ],
      },
      {
        path: 'store_categories',
        children: [
          { element: <StoreCategoryListPage />, index: true },
          { path: 'list', element: <StoreCategoryListPage /> },
          { path: 'new', element: <StoreCategoryCreatePage /> },
          { path: ':id/edit', element: <StoreCategoryEditPage /> },
        ],
      },
      {
        path: 'product_addon_categories',
        children: [
          { element: <ProductAddonsListPage />, index: true },
          { path: 'list', element: <ProductAddonsListPage /> },
          { path: 'new', element: <ProductAddonsCreatePage /> },
          { path: ':id', element: <ProductAddonsDetailsPage /> },
          { path: ':id/edit', element: <ProductAddonsEditPage /> },
        ],
      },
      {
        path: 'product_combos',
        children: [
          { element: <ProductCombosListPage />, index: true },
          { path: 'list', element: <ProductCombosListPage /> },
          { path: 'new', element: <ProductCombosCreatePage /> },
          { path: ':id', element: <ProductAddonsDetailsPage /> },
          { path: ':id/edit', element: <ProductsComboEditPage /> },
        ],
      },
      {
        path: 'banned_users',
        children: [
          { element: <BannedUserListPage />, index: true },
          { path: 'list', element: <BannedUserListPage /> },
          { path: 'new', element: <BannedUserCreatePage /> }
        ],
      },
      {
        path: 'whatsapp_test',
        children: [
          { element: <WhatsappTestListPage />, index: true },
          { path: 'list', element: <WhatsappTestListPage /> }
        ],
      },
      {
        path: 'paused_users',
        children: [
          { element: <PausedUserListPage />, index: true },
          { path: 'list', element: <PausedUserListPage /> },
          { path: 'new', element: <PausedUserCreatePage /> }
        ],
      },
    ],
  },
];
