// @mui
import Box, { BoxProps } from '@mui/material/Box';
// hooks
import { useResponsive } from 'src/hooks/use-responsive';
// components
import { useSettingsContext } from 'src/components/settings';
//
import { useTheme } from '@mui/material/styles';
import { useAuthContext } from 'src/auth/hooks';
import { HEADER, NAV } from '../config-layout';

import StoreOpenClose from '../../sections/overview/app/store-open-close';
// ----------------------------------------------------------------------

const SPACING = 8;

export default function Main({ children, sx, ...other }: BoxProps) {
  const settings = useSettingsContext();

  const { user } = useAuthContext();
  const userRoles = user?.userRoles;

  const theme = useTheme();

  const lgUp = useResponsive('up', 'md');  
  const isNavHorizontal = settings.themeLayout === 'horizontal';

  const isNavMini = settings.themeLayout === 'mini';

  const getIconStyle = (color: string) => ({
    fontSize: theme.typography.h1.fontSize,
    mb: theme.spacing(2),
    color,
  });

  if (isNavHorizontal) {
    return (
      <Box
        component="main"
        sx={{
          minHeight: 1,
          display: 'flex',
          flexDirection: 'column',
          pt: `${HEADER.H_MOBILE + 24}px`,
          pb: 10,
          ...(lgUp && {
            pt: `${HEADER.H_MOBILE * 2 + 40}px`,
            pb: 15,
          }),
        }}
      >
        {children}
      </Box>
    );
  }

  return (
    <Box
      component="main"
      sx={{
        flexGrow: 1,
        py: `${HEADER.H_MOBILE + SPACING}px`,
        ...(lgUp && {
          py: `${HEADER.H_DESKTOP + SPACING}px`,
          px: 2,
          width: `calc(100% - ${NAV.W_VERTICAL}px)`,
          ...(isNavMini && {
            width: `calc(100% - ${NAV.W_MINI}px)`,
          }),
        }),
        ...sx,
      }}
      {...other}
    >
      {/* userRoles?.includes('ROLE_ADMIN_TIENDA') && <StoreOpenClose /> */}
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          flexWrap: 'wrap',
          gap: theme.spacing(2), // This creates space between items         
        }}
        pt={{xs: theme.spacing(8), sm: theme.spacing(10), md: theme.spacing(2)}}
      >
        {children}
      </Box>
    </Box>
  );
}
