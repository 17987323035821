// @mui
import {
  Card,
  Checkbox,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  Stack,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
  styled,
  SvgIcon,
  Grid,
  FormHelperText,
  Box,
  Collapse,
  IconButton,
  SwipeableDrawer,
  Drawer,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';

import useMediaQuery from '@mui/material/useMediaQuery';
import { set } from 'src/redux/slices/store';

// Icons
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import Inventory2 from '@mui/icons-material/Inventory2';
import Liquor from '@mui/icons-material/Liquor';
import StorefrontIcon from '@mui/icons-material/Storefront';
import StoreIcon from '@mui/icons-material/Store';
import Moped from '@mui/icons-material/Moped';

import VariantListView from 'src/sections/product-addons/view/modal/product-addons-list-view';
import Scrollbar from 'src/components/scrollbar';
import useStores from 'src/hooks/use-stores';
import axios, { API_ENDPOINTS } from 'src/utils/axios';
import { enqueueSnackbar } from 'src/components/snackbar';
import { useEffect, useState, useCallback, useRef } from 'react';
import { RootState, useDispatch, useSelector } from 'src/redux/store';
import { useBoolean } from 'src/hooks/use-boolean';
import Iconify from 'src/components/iconify';
import { grey } from '@mui/material/colors';
import SvgColor from 'src/components/svg-color';
import ProductModalListView from 'src/sections/product/view/modal/product-list-modal-view';
import { paths } from 'src/routes/paths';
import { useRouter } from 'src/routes/hook';
import ProductAddonsDrawer from 'src/sections/product-addons/product-addons-drawer';

// ----------------------------------------------------------------------

const defaultFilters: {
  store: string;
  status: string;
} = {
  store: '',
  status: 'all',
};

export default function StoreOpenClose() {
  const theme = useTheme();
  const [stores] = useStores();
  const [myStores, setMyStores] = useState<any[]>([]);
  const [filters, setFilters] = useState(defaultFilters);
  const [openStore, setOpenStore] = useState();
  const [currentStore, setCurrentStore] = useState<any>();
  const [delivery, setDelivery] = useState<boolean>(false);
  const dispatch = useDispatch();
  const stateStore: any = useSelector((state: RootState) => state.store);
  const currentStoreRef = useRef<any>(null);
  const [isProductModalOpen, setProductModalOpen] = useState(false);
  const [isVariantModalOpen, setVariantModalOpen] = useState(false);
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [preparationTime, setPreparationTime] = useState(currentStore?.waiting_time || '');

  const handleVariantModalOpen = () => setVariantModalOpen(true);
  const handleVariantModalClose = () => setVariantModalOpen(false);
  const collapse = useBoolean(false);

  useEffect(() => {
    const stores_aux = stores.map((store: any) => ({
      ...store,
      open: store.state.name === 'Open',
      delivery: store.delivery_paused,
      closed: store.closed_paused,
    }));
    setMyStores(stores_aux);
    if (stateStore?.id) {
      const TIENDA = stores_aux.find((item) => item.id === stateStore.id);
      if (TIENDA && TIENDA !== currentStoreRef.current) {
        currentStoreRef.current = TIENDA;
        setCurrentStore(TIENDA);
      }
    } else if (stores_aux.length > 0) {
      setCurrentStore(stores_aux[0]);
    }
  }, [stateStore?.id, stores]);
  useEffect(() => {
    if (currentStore) {
      if (currentStore.waiting_time) {
        const min = currentStore.waiting_time.split(' ')[0];
        setPreparationTime(min);
      } else {
        setPreparationTime('');
      }
    }
    if (
      !currentStoreRef?.current ||
      (currentStoreRef.current && currentStoreRef.current.id !== currentStore.id)
    ) {
      currentStoreRef.current = currentStore;
      localStorage.setItem('currentStore', JSON.stringify(currentStore));
      dispatch(set(currentStore));
    }
  }, [dispatch, currentStore]);

  const handleFilters = useCallback(
    (name: string, value: any) => {
      setFilters((prevState) => ({
        ...prevState,
        [name]: value,
      }));
      localStorage.setItem('SelectStore', value);

      const storeResult = myStores.filter((data) => data.id === value);
      storeResult.map((opens) => setOpenStore(opens.open));
    },
    [myStores]
  );

  const handleDeliveryState = (storeId: string, deliveryActive: boolean) => {
    setCurrentStore({ ...currentStore, delivery: !deliveryActive });
    const URL = deliveryActive
      ? API_ENDPOINTS.store.setDelivery(storeId)
      : API_ENDPOINTS.store.setDelivery(storeId);
    axios
      .put(URL)
      .then(({ data }) => {
        enqueueSnackbar(deliveryActive ? 'Delivery reanudado' : 'Sin delivery por el momento');
      })
      .catch((error) => {
        /* Handle error */
      });
  };

  const handleClosedState = (storeId: string, closedActive: boolean) => {
    setCurrentStore({ ...currentStore, closed: !closedActive });
    const URL = closedActive
      ? API_ENDPOINTS.store.setClosed(storeId)
      : API_ENDPOINTS.store.setClosed(storeId);
    axios
      .put(URL)
      .then(({ data }) => {
        enqueueSnackbar(closedActive ? 'Tienda Abierta' : 'Tienda temporalmente cerrada');
      })
      .catch((error) => {
        /* Handle error */
      });
  };

  const updatePreparationTime = (newPreparationTime: string) => {
    if (currentStore?.id) {
      axios
        .put(API_ENDPOINTS.store.setPreparationTime(currentStore.id, newPreparationTime))
        .then(() => {
          setPreparationTime(newPreparationTime);
          enqueueSnackbar('Tiempo de preparación actualizado con éxito', { variant: 'success' });
          // Update local state or re-fetch store details as needed
        })
        .catch((error) => {
          enqueueSnackbar('Error al actualizar el tiempo de preparación', { variant: 'error' });
          console.error(error);
        });
    }
  };

  const handleState = (storeId: string, open: boolean) => {
    setCurrentStore({ ...currentStore, open: !open });
    const URL = open ? API_ENDPOINTS.store.close(storeId) : API_ENDPOINTS.store.open(storeId);
    axios
      .put(URL)
      .then(({ data }) => {
        enqueueSnackbar(open ? 'Tienda cerrada' : 'Tienda abierta');
      })
      .catch((error) => {});
  };

  const handleModalOpen = () => setProductModalOpen(true);
  const handleModalClose = () => setProductModalOpen(false);
  const router = useRouter();
  const showProductList = () => {
    router.push(paths.dashboard.product.root);
  };
  const MaterialUISwitch = styled(Switch)(() => ({
    width: 62,
    height: 34,
    padding: 9,
    '& .MuiSwitch-switchBase': {
      margin: 1,
      padding: 0,
      transform: 'translateX(6px)',
      '&.Mui-checked': {
        color: '#fff',
        transform: 'translateX(22px)',
        '& .MuiSwitch-thumb:before': {
          backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
            '#fff'
          )}" d="M9.305 1.667V3.75h1.389V1.667h-1.39zm-4.707 1.95l-.982.982L5.09 6.072l.982-.982-1.473-1.473zm10.802 0L13.927 5.09l.982.982 1.473-1.473-.982-.982zM10 5.139a4.872 4.872 0 00-4.862 4.86A4.872 4.872 0 0010 14.862 4.872 4.872 0 0014.86 10 4.872 4.872 0 0010 5.139zm0 1.389A3.462 3.462 0 0113.471 10a3.462 3.462 0 01-3.473 3.472A3.462 3.462 0 016.527 10 3.462 3.462 0 0110 6.528zM1.665 9.305v1.39h2.083v-1.39H1.666zm14.583 0v1.39h2.084v-1.39h-2.084zM5.09 13.928L3.616 15.4l.982.982 1.473-1.473-.982-.982zm9.82 0l-.982.982 1.473 1.473.982-.982-1.473-1.473zM9.305 16.25v2.083h1.389V16.25h-1.39z"/></svg>')`,
        },
        '& + .MuiSwitch-track': {
          opacity: 1,
          backgroundColor: theme.palette.mode === 'dark' ? '#8796A5' : '#aab4be',
        },
      },
    },
    '& .MuiSwitch-thumb': {
      backgroundColor: theme.palette.mode === 'dark' ? '#003892' : '#001e3c',
      width: 32,
      height: 32,
      '&:before': {
        content: "''",
        position: 'absolute',
        width: '100%',
        height: '100%',
        left: 0,
        top: 0,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
          '#fff'
        )}" d="M4.2 2.5l-.7 1.8-1.8.7 1.8.7.7 1.8.6-1.8L6.7 5l-1.9-.7-.6-1.8zm15 8.3a6.7 6.7 0 11-6.6-6.6 5.8 5.8 0 006.6 6.6z"/></svg>')`,
      },
    },
    '& .MuiSwitch-track': {
      opacity: 1,
      backgroundColor: '#8796A5',
      borderRadius: 20 / 2,
    },
  }));
  const renderMobile = (
    <Stack
      direction={{ xs: 'column', sm: 'row' }}
      alignContent="center"
      sx={{
        flexShrink: 1,
        '& > *': {
          flexBasis: '100%',
        },
      }}
    >
      <Stack sx={{ mb: { xs: 2, sm: 0 } }}>
        <Stack direction={{ xs: 'column', md: 'row' }} spacing={1}>
          <Stack
            direction={myStores && myStores.length === 1 ? 'row' : 'column'}
            spacing={2}
            textAlign="center"
          >         
            <Stack direction="row" spacing={1} alignSelf="center">
              <Typography
                alignSelf="center"
                variant="caption"
                sx={{
                  color: !currentStore?.open ? 'error.main' : '',
                  fontWeight: !currentStore?.open ? 'bold' : '',
                }}
              >
                Cerrado
              </Typography>
              <MaterialUISwitch
                onChange={() => handleState(currentStore?.id, currentStore?.open)}
                checked={currentStore?.open}
                inputProps={{ 'aria-label': 'controlled' }}
              />
              <Typography
                alignSelf="center"
                variant="caption"
                sx={{
                  color: currentStore?.open ? 'success.main' : '',
                  fontWeight: currentStore?.open ? 'bold' : '',
                }}
              >
                Abierto
              </Typography>
            </Stack>
          </Stack>

          {!collapse.value && (
            <IconButton
              color={collapse.value ? 'inherit' : 'default'}
              onClick={collapse.onToggle}
              sx={{
                backgroundColor: grey[200],
                borderRadius: 2,
                position: 'relative',
                p: 0,
              }}
            >
              <Iconify icon="eva:arrow-ios-downward-fill" />
            </IconButton>
          )}

          {/* <Collapse in={collapse.value}> */}
          <Drawer
            hideBackdrop
            PaperProps={{
              sx: { p: 2, borderBottomLeftRadius: 15, borderBottomRightRadius: 15 },
            }}
            anchor="top"
            open={collapse.value}
            onClose={() => {}}
          >
            <Stack direction="column" alignSelf="center" spacing={1}>
              <Typography variant="caption" alignSelf="center" fontWeight="bold" my={1}>
                Configuracion adicional
              </Typography>
              <Box sx={{ flex: 1 }}>
                <Select
                  value={currentStore?.id || ''}
                  onChange={(ev) => {
                    const SELECTED = ev.target.value;
                    const STORE_SELECTED: any = myStores.find(
                      (store: any) => store.id === SELECTED
                    );
                    setCurrentStore(STORE_SELECTED);
                  }}
                  input={<OutlinedInput label="Comercios" />}
                  renderValue={(selected) =>
                    myStores.find((store: any) => store.id === selected)?.name
                  }
                  sx={{ textTransform: 'capitalize', width: '100%' }}
                >
                  {myStores &&
                    myStores.map((option) => (
                      <MenuItem key={option.id} value={option.id}>
                        {option.name}
                      </MenuItem>
                    ))}
                </Select>
              </Box>
              <Box sx={{ flex: 1 }}>
                <Select
                  labelId="preparation-time-label"
                  value={preparationTime}
                  onChange={(event) => updatePreparationTime(event.target.value)}
                  label="Tiempo de preparación"
                  sx={{ width: '100%' }}
                  input={<OutlinedInput label="Demora actual" />}
                >
                  <MenuItem value="0">No indicar</MenuItem>
                  <MenuItem value="15">15 minutos</MenuItem>
                  <MenuItem value="30">30 minutos</MenuItem>
                  <MenuItem value="45">45 minutos</MenuItem>
                  <MenuItem value="60">60 minutos</MenuItem>
                </Select>
              </Box>
              {!!currentStore && (
                <Stack direction="row" sx={{ flex: 1 }} spacing={1}>
                  <Stack direction="row" spacing={1}>
                    <Stack direction="column" spacing={0.5} alignItems="center">
                      <Stack direction="row" spacing={0.5} alignItems="center">
                        {!isMobile && (
                          <SvgIcon
                            component={!currentStore?.closed ? StoreIcon : StorefrontIcon}
                            sx={{
                              color: currentStore?.closed ? 'error.main' : 'primary.main',
                              mr: 1,
                            }}
                          />
                        )}
                        <Switch
                          onChange={() => handleClosedState(currentStore?.id, currentStore?.closed)}
                          checked={currentStore?.closed}
                          inputProps={{ 'aria-label': 'controlled' }}
                        />
                      </Stack>
                      <Typography
                        variant="caption"
                        sx={{
                          mt: -1,
                          fontWeight: 'bold',
                          color: 'primary.main',
                          textAlign: 'center',
                        }}
                      >
                        Cerrado {isMobile && <br />} Temporal
                      </Typography>
                    </Stack>
                    <Stack direction="column" spacing={0.5} alignItems="center">
                      <Stack direction="row" spacing={1} alignItems="center">
                        {!isMobile && (
                          <SvgIcon
                            component={Moped}
                            sx={{ color: currentStore?.delivery ? 'error.main' : 'primary.main' }}
                          />
                        )}
                        <Switch
                          onChange={() =>
                            handleDeliveryState(currentStore?.id, currentStore?.delivery)
                          }
                          checked={currentStore?.delivery}
                          inputProps={{ 'aria-label': 'controlled' }}
                        />
                      </Stack>
                      <Typography
                        variant="caption"
                        sx={{
                          mt: -1,
                          fontWeight: 'bold',
                          color: 'primary.main',
                          textAlign: 'center',
                        }}
                      >
                        Pausar {isMobile && <br />} Delivery
                      </Typography>
                    </Stack>
                  </Stack>  
                </Stack>
              )}
              <IconButton
                color={collapse.value ? 'inherit' : 'default'}
                onClick={collapse.onToggle}
                sx={{
                  backgroundColor: grey[200],
                  borderRadius: 2,
                  position: 'relative',
                  p: 0,
                  mt: 2,
                }}
              >
                <Iconify icon="eva:arrow-ios-upward-fill" />
              </IconButton>
            </Stack>
          </Drawer>
          {/* </Collapse> */}
        </Stack>
      </Stack>
    </Stack>
  );
  const renderDesktop = (
    <Stack
      direction={{ xs: 'column', sm: 'row' }}
      alignContent="center"
      spacing={1}
      sx={{
        flexShrink: 1,
        '& > *': {
          flexBasis: '100%',
        },
      }}
    >
      <Stack sx={{ mb: { xs: 2, sm: 0 } }}>
        <Stack direction={{ xs: 'column', md: 'row' }}  spacing={1}>
         
          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <Stack direction="row" spacing={1} alignSelf="center">
              <Typography
                alignSelf="center"
                variant="caption"
                sx={{
                  color: !currentStore?.open ? 'error.main' : '',
                  fontWeight: !currentStore?.open ? 'bold' : '',
                }}
              >
                Cerrado
              </Typography>
              <MaterialUISwitch
                onChange={() => handleState(currentStore?.id, currentStore?.open)}
                checked={currentStore?.open}
                inputProps={{ 'aria-label': 'controlled' }}
              />
              <Typography
                alignSelf="center"
                variant="caption"
                sx={{
                  color: currentStore?.open ? 'success.main' : '',
                  fontWeight: currentStore?.open ? 'bold' : '',
                }}
              >
                Abierto
              </Typography>
            </Stack>
          </Box>
            {myStores && myStores.length > 1 && (
              <Box sx={{ flex: 1 }}>
                <Select
                  value={currentStore?.id || ''}
                  onChange={(ev) => {
                    const SELECTED = ev.target.value;
                    const STORE_SELECTED: any = myStores.find(
                      (store: any) => store.id === SELECTED
                    );
                    setCurrentStore(STORE_SELECTED);
                  }}
                  input={<OutlinedInput label="Comercios" />}
                  renderValue={(selected) =>
                    myStores.find((store: any) => store.id === selected)?.name
                  }
                  sx={{ textTransform: 'capitalize', width: '100%' }}
                >
                  {myStores &&
                    myStores.map((option) => (
                      <MenuItem key={option.id} value={option.id}>
                        {option.name}
                      </MenuItem>
                    ))}
                </Select>
              </Box>
            )}

            <Box sx={{ flex: 1 }}>
              <Select
                labelId="preparation-time-label"
                value={preparationTime}
                onChange={(event) => updatePreparationTime(event.target.value)}
                label="Tiempo de preparación"
                sx={{ width: '100%' }}                
                input={<OutlinedInput label="Demora actual" />}
              >
                <MenuItem value="0">No indicar</MenuItem>
                <MenuItem value="15">15 minutos</MenuItem>
                <MenuItem value="30">30 minutos</MenuItem>
                <MenuItem value="45">45 minutos</MenuItem>
                <MenuItem value="60">60 minutos</MenuItem>
              </Select>
            </Box>
          </Stack>

          {!!currentStore && (
            <Stack direction="row" sx={{ flex: 1 }} spacing={1} alignSelf='center'>
              <Stack direction="row" spacing={1}>
                <Stack direction="column" spacing={0.5} alignItems="center">
                  <Stack direction="row" spacing={0.5} alignItems="center">
                    {!isMobile && (
                      <SvgIcon
                        component={!currentStore?.closed ? StoreIcon : StorefrontIcon}
                        sx={{
                          color: currentStore?.closed ? 'error.main' : 'primary.main',
                          mr: 1,
                        }}
                      />
                    )}
                    <Switch
                      onChange={() => handleClosedState(currentStore?.id, currentStore?.closed)}
                      checked={currentStore?.closed}
                      inputProps={{ 'aria-label': 'controlled' }}
                    />
                  </Stack>
                  <Typography
                    variant="caption"
                    sx={{
                      mt: -1,
                      fontWeight: 'bold',
                      color: 'primary.main',
                      textAlign: 'center',
                    }}
                  >                    
                    Cerrado {isMobile && <br />} Temporal
                  </Typography>
                </Stack>
                <Stack direction="column" spacing={0.5} alignItems="center">
                  <Stack direction="row" spacing={1} alignItems="center">
                    {!isMobile && (
                      <SvgIcon
                        component={Moped}
                        sx={{ color: currentStore?.delivery ? 'error.main' : 'primary.main' }}
                      />
                    )}
                    <Switch
                      onChange={() => handleDeliveryState(currentStore?.id, currentStore?.delivery)}
                      checked={currentStore?.delivery}
                      inputProps={{ 'aria-label': 'controlled' }}
                    />
                  </Stack>
                  <Typography
                    variant="caption"
                    sx={{
                      mt: -1,
                      fontWeight: 'bold',
                      color: 'primary.main',
                      textAlign: 'center',
                    }}
                  >
                    Pausar {isMobile && <br />} Delivery
                  </Typography>
                </Stack>
              </Stack>             
            </Stack>
          )}
        </Stack>
      </Stack>
    </Stack>
  );
  return (
    <>
      {isMobile ? renderMobile : renderDesktop}    
    </>
  );
}
